import React from 'react';
import Link from '@sentry/static-shared/components/link';
import styled from '@emotion/styled';
import { mqMin } from '@sentry/static-shared/utils/css';
import { white, mdPink } from '@sentry/static-shared/utils/css/colors';
import {
  FlexGrid,
  FlexGridCell,
} from '@sentry/static-shared/components/FlexGrid';
import Layout from '../components/layout';
import Layer from '@sentry/static-shared/components/Layer';
import Prose from '@sentry/static-shared/components/Prose';

import { TextAlign } from '@sentry/static-shared/components/Typography';

import SEO from '../components/seo';
import { useLocation } from "@reach/router";
import { canonicalURL } from '@sentry/static-shared/utils/CanonicalLink';

import useDocs from '../hooks/use-docs';
import LinkListArticles from '@sentry/static-shared/components/link-list-articles';

import LinkCard from '@sentry/static-shared/components/link-card';

import Button from '@sentry/static-shared/components/Button';
import ChevronRight from '@sentry/static-shared/icons/icon-chevron-right.svg';

import metaImg from '../assets/meta/open-source-meta.jpg';
import heroImg from '../assets/open-source-hero-xl.jpg';
import repoIcon from '../assets/small-book-icon.png';
import howItWorksImg from '../assets/how-sentry-works.png';

import discordImg from '@sentry/static-shared/images/tier-3-icons/discord-icon.png';
import githubImg from '@sentry/static-shared/images/tier-3-icons/github-icon.png';
import twitterImg from '@sentry/static-shared/images/tier-3-icons/twitter-icon.png';

const featuredRepos = [
  {
    url: 'https://github.com/getsentry/responses',
    name: 'Responses',
    blurb: 'A utility library for mocking out the requests Python library.',
  },
  {
    url: 'https://github.com/getsentry/symbolic',
    name: 'Symbolic',
    blurb:
      'A library written in Rust which is used at Sentry to implement symbolication of native stack traces, sourcemap handling for minified JavaScript and more.',
  },
  {
    url: 'https://github.com/getsentry/craft',
    name: 'Craft',
    blurb:
      'A command line tool that helps to automate and pipeline package releases.',
  },
];

const OpenSourcePage = () => {
  const docs = useDocs();
  const locationSlug = useLocation();
  const canonicalLink = canonicalURL('open', locationSlug.pathname);

  return (
    <Layout>
      <SEO
        title={`We’re Fairly Open`}
        description={`Sentry's building a trustworthy product that developers actually want to use. Help us.`}
        image={metaImg}
        canonical={canonicalLink}
      />

      <HeroLayer
        {...{
          overlap: 'right',
          backgroundComponent: <HeroImage />,
        }}
      >
        <HeroGrid justifyContent="left">
          <FlexGridCell lg={6}>
            <h1>We're Fairly Open</h1>
            <p className="intro">
              Help us build a product you actually want to use.
            </p>
            <p>
              We like our product. But even a good thing can be better. Find
              bugs? Want features? Let's talk about it.
            </p>
            <Button
              to="https://github.com/getsentry/sentry/issues/new?template=feature.yml"
              variant="primary"
            >
              Submit a feature request
            </Button>
            <Button
              to="https://github.com/getsentry/sentry/issues/new?template=bug.yml"
              variant="silent"
            >
              Submit a bug report
            </Button>
          </FlexGridCell>
        </HeroGrid>
      </HeroLayer>

      <Layer background="white" overlap="jag-right">
        <TextAlign xs="center">
          <Prose disableHeadingSpace={true}>
            <h2>Talk Sentry With Internet Strangers</h2>
          </Prose>
        </TextAlign>
        <StyledFlexGridMargins>
          <FlexGridCell xs={6} md={3}>
            <TextAlign xs="center">
              <a href="https://github.com/getsentry/">
                <IconImg src={githubImg} />
              </a>
              <Button
                to="https://github.com/getsentry/"
                variant="quiet"
                chevron={ChevronRight}
              >
                Github
              </Button>
            </TextAlign>
          </FlexGridCell>

          <FlexGridCell xs={6} md={3}>
            <TextAlign xs="center">
              <a href="https://discord.com/invite/Ww9hbqr">
                <IconImg src={discordImg} />
              </a>
              <Button
                to="https://discord.com/invite/Ww9hbqr"
                variant="quiet"
                chevron={ChevronRight}
              >
                Discord
              </Button>
            </TextAlign>
          </FlexGridCell>

          <FlexGridCell xs={6} md={3}>
            <TextAlign xs="center">
              <a href="https://twitter.com/getsentry">
                <IconImg src={twitterImg} />
              </a>
              <Button
                to="https://twitter.com/getsentry"
                variant="quiet"
                chevron={ChevronRight}
              >
                Twitter
              </Button>
            </TextAlign>
          </FlexGridCell>
        </StyledFlexGridMargins>
      </Layer>

      <Layer background="chaos-dark" overlap="jag-left">
        <h2>Peruse Our Actually Open Source Projects</h2>
        <p>
          Our SDKs are Open Source. But that's a given. We also publish a bunch
          of other Open Source tools.
        </p>
        <StyledRepoList>
          {featuredRepos.map(repo => {
            const repoCardTitle = (
              <span key={repo.name}>
                <ImgRepoIcon src={repoIcon} /> {repo.name}
              </span>
            );
            return (
              <li>
                <LinkCard
                  to={repo.url}
                  title={repoCardTitle}
                  subtitle={repo.blurb}
                  callToAction="check it out"
                />
              </li>
            );
          })}
        </StyledRepoList>

        <Button
          to="https://github.com/getsentry/?sort=stargazers"
          variant="silent"
          chevron={ChevronRight}
          compensate="left"
        >
          See what we've made
        </Button>
      </Layer>

      <Layer background="white" overlap="jag-right">
        <FlexGrid justifyContent="center" alignItems="center">
          <FlexGridCell lg={7}>
            <StyledImg src={howItWorksImg} alt="" />
          </FlexGridCell>

          <FlexGridCell lg={5}>
            <h2>Wait, how does this work?</h2>
            <StyledLinkListArticles>
              {docs.map(doc => {
                return (
                  <li key={doc.title}>
                    <Link to={doc.slug}>
                      <span>{doc.title}:</span> {doc.subtitle}
                    </Link>
                  </li>
                );
              })}
            </StyledLinkListArticles>
          </FlexGridCell>
        </FlexGrid>
      </Layer>

      <Layer background="chaos-dark">
        <StyledTextAlign xs="center">
          <h2>Want to run Sentry yourself? Yeah, you can do that.</h2>
          <Button
            to="https://develop.sentry.dev/self-hosted/"
            variant="primary"
          >
            Read Self-Hosted Sentry Docs
          </Button>
        </StyledTextAlign>
      </Layer>
    </Layout>
  );
};

const IconImg = styled.img`
  max-width: 100%;
  max-height: 14rem;
  margin: 0 auto;
  display: block;
`;

const StyledFlexGridMargins = styled(FlexGrid)`
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  justify-content: center;

  > div {
    margin-bottom: 1rem;
  }
`;

const HeroLayer = styled(Layer)`
  color: ${white};
`;

const HeroImage = styled.div`
  background-color: #310f30;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-image: url(${heroImg});
  background-size: auto 350px;
  background-position: 48% bottom;

  ${mqMin('sm')} {
    background-size: auto 400px;
  }

  ${mqMin('md')} {
    background-size: auto 500px;
  }

  ${mqMin('lg')} {
    background-size: auto 550px;
    background-position: calc(50% + 300px) calc(50% - 25px);
  }

  ${mqMin('xl')} {
    background-size: auto 700px;
    background-position: calc(50% + 375px) center;
  }
`;

const HeroGrid = styled(FlexGrid)`
  margin-bottom: 300px;
  margin-top: 1rem;

  a.btn {
    margin-bottom: 0.5rem;
  }

  a.btn.silent {
    color: ${mdPink};
  }

  ${mqMin('sm')} {
    margin-bottom: 350px;
  }

  ${mqMin('md')} {
    margin-bottom: 400px;
  }

  ${mqMin('lg')} {
    margin-bottom: 1rem;
  }

  ${mqMin('xl')} {
    margin-top: 4rem;
    margin-bottom: 6rem;
  }
`;

const StyledRepoList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-between;

  li {
    width: calc(20% - 1rem);
    flex: 0 0 100%;

    ${mqMin('md')} {
      flex-basis: calc(33.33% - 1rem);
    }

    p.link-card-title {
      font-size: 1.5em;
      margin: 0;
    }
  }
`;

const ImgRepoIcon = styled.img`
  height: 1.75rem;
  position: relative;
  top: -2px;
`;

const StyledLinkListArticles = styled(LinkListArticles)`
  background: yellow;
`;

const StyledTextAlign = styled(TextAlign)`
  margin-bottom: 1rem;

  ${mqMin('md')} {
    margin-bottom: 4rem;
  }
`;

const StyledImg = styled.img`
  width: 100%;
`;

export default OpenSourcePage;
