import { useStaticQuery, graphql } from 'gatsby';

const useDocs = () => {
  const { allFile } = useStaticQuery(
    graphql`
      query DocsQuery {
        allFile(filter: { dir: { regex: "//content$/" } }) {
          nodes {
            childMdx {
              frontmatter {
                title
                subtitle
                order
              }
            }
            childJavascriptFrontmatter {
              frontmatter {
                title
                subtitle
                order
              }
            }
            fields {
              slug
            }
          }
        }
      }
    `
  );

  const files = allFile.nodes.map(node => {
    let child = null;
    if (node.childMdx) child = node.childMdx;
    if (node.childJavascriptFrontmatter)
      child = node.childJavascriptFrontmatter;

    return {
      slug: node.fields.slug,
      ...(child && child.frontmatter),
    };
  });

  files.sort((a, b) => a.order - b.order);

  return files;
};

export default useDocs;
